<template>
  <v-row id="invoiceContentB" style="padding: 20px">
      <v-col sm="12" cols="12" class="" style="background: #eee!important;text-align: center!important;">
          <h2 style="margin-top: 0!important; text-align: center!important;" class="text-left mt-4">{{$t('card_info')}}</h2>
      </v-col>
      <v-col sm="12" cols="12" class="">
          <v-simple-table>
              <template v-slot:default>
                  <tbody class="tb-pong">
                      <tr>
                          <td class="text-left px-0" style="width: 50%;font-size: 1.4rem;">
                              {{$t('card_number')}}
                          </td>
                          <td class="text-center pl-0">:</td>
                          <td class="text-right px-0 " style="font-size: 1.4rem;">
                              {{printObj.cardNumber}}
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left px-0" style="width: 50%;font-size: 1.4rem;padding-bottom: 15px;">
                              {{$t('serial')}}
                          </td>
                          <td class="text-center pl-0">:</td>
                          <td class="text-right px-0" style="font-size: 1.4rem;">
                              {{printObj.serial}}
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left px-0" style="width: 50%;">
                              {{$t('institute_number')}}
                          </td>
                          <td class="text-center pl-0">:</td>
                          <td class="text-right px-0">
                              {{instNum}}
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left pl-0">
                              {{$t('store_name')}}
                          </td>
                          <td class="text-center pl-0">:</td>
                          <td class="text-right px-0">
                              {{ activeStore.name }}
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left pl-0">
                              {{$t('store_phone')}}
                          </td>
                          <td class="text-center pl-0">:</td>
                          <td class="text-right px-0">
                              {{ activeStore.phone }}
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left pl-0">
                              {{$t('cashier')}}
                          </td>
                          <td class="text-center pl-0">:</td>
                          <td class="text-right px-0">
                              {{Object.keys(printObj.pinUser).length > 0 ? printObj.pinUser.name : ''}}
                          </td>
                      </tr>  
                      <tr>
                          <td class="text-left pl-0">
                              {{$t('price')}}
                          </td>
                          <td class="text-center pl-0">:</td>
                          <td class="text-right px-0">
                              {{numberFormat(printObj.cardPrice)}}
                          </td>
                      </tr>  
                      <tr>
                          <td class="text-left px-0" style="width: 50%;">
                              {{$t('date')}}
                          </td>
                          <td class="text-center pl-0">:</td>
                          <td class="text-right px-0">
                              {{dateTimeFormat(new Date())}}
                          </td>
                      </tr>
                      <tr>
                          <td class="text-left px-0">
                              {{$t('counter_name')}}
                          </td>
                          <td class="text-center pl-0">:</td>
                          <td class="text-right px-0">
                              {{ counterName }}
                          </td>
                      </tr>
                  </tbody>
              </template>
          </v-simple-table>
      </v-col>
      <v-col v-if="printObj.qrvalue != ''" class="text-right my-4" sm="12" cols="12">
          <qr-code style="margin: 0 auto; margin-bottom: 10px;margin-top: 10px;" :text="printObj.qrvalue != '' ? printObj.qrvalue : 'none'"></qr-code>
      </v-col>
      
      <v-col class="text-right my-4" sm="12" cols="12" style="text-align: center!important;margin: 0!important;padding: 6px;background: none;border-top: 1px solid #eee;overflow: hidden;">
          <img src="https://s3.ap-southeast-1.amazonaws.com/images.banhji/benchlogo.png" style="height: 40px; width: auto;" />
      </v-col>
  </v-row>
</template>

<script>
// import { i18n } from "@/i18n";
import kendo from "@progress/kendo-ui"
const intHandler = require("@/scripts/instituteHandler")
const commerceHandler = require("@/scripts/commerce/handler/commerceHandler")
const session = localStorage.getItem('banhjipossession') != null ? JSON.parse(localStorage.getItem('banhjipossession')) : {}
// const inst = session.ins || {}
const instituteId = localStorage.getItem('instituteId') != null ? localStorage.getItem('instituteId') : ''
const cookie = session.user || {}
import VueQRCodeComponent from 'vue-qrcode-component'
export default {
  name: "PrintReceipt",
  props: {printObj: {}},
  data: () => ({
      miniVariant: false,
      kendo: kendo,
      decimal: 0,
      logoUrl: '',
      institute: {},
      instNum: instituteId.substring(5),
      counterName: localStorage.getItem(instituteId + 'commRegister') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRegister')).name : '',
      activeStore: localStorage.getItem(instituteId + 'commStore') != null ? JSON.parse(localStorage.getItem(instituteId + 'commStore')) : {},
      register: localStorage.getItem(instituteId + 'commRegister') != null ? JSON.parse(localStorage.getItem(instituteId + 'commRegister')) : {},
      receiptTemplate: 'A4',
      isHideColumn: false,
      qrsize: 500,
      qrvalue: 'abc',
  }),  
  methods: {
      dateTimeFormat(date){
          return kendo.toString(new Date(date), 'dd/MMM/yyyy HH:mm:ss');
      },
      numberFormat(value) {
          return kendo.toString(parseFloat(value), `n${this.decimal}`);
      },
      async loadSetting(){
          window.console.log(this.printObj, 'print obj')
          this.isHideColumn = false
          let qrval = {
              cardNumber: this.printObj.cardNumber,
              serial: this.printObj.serial
          }
          this.qrvalue = JSON.stringify(qrval)
          window.console.log(this.qrvalue, 'qr value')
          commerceHandler.settingGet(this.register.pk).then(res => {
              if (res.data.statusCode === 200) {
                  const data = res.data.data
                  if (data.length > 0) {
                      this.receiptTemplate = data[0].receiptTemplate || 'A4'
                      this.decimal = data[0].decimal
                      window.console.log(this.receiptTemplate, 'receipt template')
                      if(this.receiptTemplate == '80mm' || this.receiptTemplate == '58mm'){
                          this.isHideColumn = true
                      }
                  }
              }
          })
      }
  },
  components: {
      'qr-code': VueQRCodeComponent
  },
  computed:{
  },
  created: async function () {
      await this.loadSetting()
      await intHandler.init().then(res=>{
          this.institute = res.data.data
      })
      intHandler.getImage(cookie.creator).then(res=>{
          if(res.length > 0){
              this.logoUrl = res[0].url || ''
          }
      })
  },
  
};
</script>
<style scoped>
  .tb_receipt{
      border: 0.2px solid black;
      border-right: 0px;
  }
  .tb_receipt_r{
      border: 0.2px solid black;
  }
  .th_invoice{
      border: 0.2px solid black;
      border-right: 0px;
      color: black !important;
      font-weight: 700;
  }
  .th_invoice_r{
      border: 0.2px solid black;
      color: black !important;
      font-weight: 700;
  }
  #pin > .theme--light.v-input input {
      max-height: 32px;
      font-size: 2rem !important;
      text-align: center;
  }
  .v-image__image--cover {
      background-size: contain;
      margin-top: 5px;
  }
  .v-image__image {
      background-position: top center !important;
  }
  .b-cash:before {
      color: #ffffff;
  }   
  .b-loyalty:before {
      content: "\e91b";
      color: #ffffff;
  }
  .btn-right .v-btn__content i{
     font-size: 40px;
  }
  .btn-right .v-btn__content{
     display: block !important;
  }
  h6{
     color: #2a2b2b;
     font-size: 0.6rem !important; 
     font-family: "Niradei-bold", serif !important;
     padding-top: 5px;
  }
  
  .btn-right{
      background-color: #ffffff !important;
      border-bottom: 1px solid;
      border-color: #e5e5e6 !important;
      width: 100%;
      height: 12% !important;
      text-align: center;
      padding: 0 3px !important;
      border-radius: 0px !important;  
  }
  .v-tab {
      justify-content: left;
      font-size: 20px;
  }

  .v-tab--active {
      background-color: rgb(255, 255, 255);
  }

  .tab_setting .v-tab--active {
      font-weight: 700;
      color: #000;
  }

  .v-tab--active {
      background-color: #ffffff !important;
      border-bottom: 4px solid #92d050;
      border-left: none;
  }

  p {
      color: rgba(0, 0, 0, 0.87);
  }
  .theme--light.v-tabs-items {
      background-color: #FFFFFF;
      height: 550px !important;
      overflow: scroll !important;
  }
  
  .sale-container{
      max-width: 1870px !important;
  }
  .theme--light.v-tabs-items {
      background-color: #ffffff00 !important;
  }
  .card-item{
      background-color: #ffffff00;
      border: 0.2px solid #dcdcdc33;
      border-radius: 0;
  }
  .catagory-btn{
      width: 100%;
      background-color: #ffffff !important;
      border: 0.2px solid #e4e4e6;
  }
  .v-btn__content{
      font-family: "Niradei-Bold", serif !important;
      display: block !important;
  }
  .btn_h2{
      font-size: 22px;
  }
  .sidebar-left{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 12.666667%;
      flex: 0 0 12.666667%;
      max-width: 12.666667%;
  }
  .sidebar-left2{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 38.666667%;
      flex: 0 0 38.666667%;
      max-width: 38.666667%;
  }
  .sidebar-left3{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 7.000000%;
  flex: 0 0 7.000000%;
  max-width: 7.000000%;
  }
  .container h2 {
      font-size: 17px;
      margin-bottom: 0;
  }
  .btn-sidebar{
      justify-content: space-between !important;
      background-color: #fff !important;
      font-family: "Niradei-bold", serif !important;
  }

  .b-payment:before {
      content: "\e91f";
      color: #ffffff;
  }
  .b-banking:before {
      content: "\e90e";
      color: #ffffff;
  }
  .btn-money {
      background-color: #969696 !important;
      color: white;
  }
  
  .calculator{
      border: 1px solid gainsboro;
  }
  .receipt-btn{
      min-width: 22% !important;
      height: 50px !important;
      padding: 10px !important;
      margin: 5px;
  }
  .apply-btn{
      min-width: 33% !important;
      height: 50px !important;
      padding: 5px !important;
      margin: 5px;
  }
  .calculator-btn{
     min-width: 18% !important;
      margin: 4px;
      padding: 0 10px !important;
  }
  .calculator-btn-1{
      min-width: 20% !important;
      margin: 4px;
      padding: 0 2px !important;
      font-size: 13px !important;
      letter-spacing: inherit;
      font-weight: bold;
  }
  .pay-btn{
      width: 100%;
      border: 1px solid #e0e0e0;
  }
  .v-text-field__detail .field-pos{
      display:none !important;
  }
  .btn-funtion{
      font-size: 14px;
      width: 100% !important;
      display: flex;
      justify-content: space-between;
  }
  .function_content {
      padding: 0px;
      border-bottom: none !important;
      background-color: #f8f8f9;
  }
  .notification{
      background-color: #ed263a;
      width: auto;
      margin-left: 2px;
      color: #fff;

  }
  .v-btn.v-size--default {
      font-size: 1.2rem;
  }
  .b-search.b:before {
      color: #d7d3d3 !important;
  }
  /* pin */
  body {
    height: 95vh;
    background-color: #181c26 !important;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-family: Open Sans;
  }

  body.wrong {
    -webkit-animation: bg-red 1s ease-in;
    animation: bg-red 1s ease-in;
  }

  body.correct {
    -webkit-animation: bg-green 1s ease-in;
    animation: bg-green 1s ease-in;
  }

  #inspiration {
    position: fixed;
    right: 1em;
    bottom: 1em;
  }

  #inspiration a {
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    color: white;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  #inspiration a:hover { color: #212121; }

  #inspiration p {
    margin: 0;
    padding-left: .4em;
    display: inline-block;
    color: rgba(255, 255, 255, 0.6);
  }
  #pin {
      background-color: #ffffff !important;
      width: 90%;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      /* padding: 1em; */
      border-radius: .3em;
      /* box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3); */
      margin: auto;
      color: rgb(155 27 46);;
      }

  .dots {
    width: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 1em;
    padding-top: 3em;
  }

  .dot {
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 0.8em;
    width: 0.8em;
    height: 0.8em;
    -webkit-transform: scale3d(0.7, 0.7, 0.7);
    transform: scale3d(0.7, 0.7, 0.7);
  }

  .dot.active {
    -webkit-animation: growDot .5s ease;
    animation: growDot .5s ease;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .dot.wrong {
    -webkit-animation: wrong .9s ease;
    animation: wrong .9s ease;
  }

  .dot.correct {
    -webkit-animation: correct .9s ease;
    animation: correct .9s ease;
  }

  .cancelPin {
    width: 25%;
    margin-left: 10%;
    margin-top: 10%;
  }
  .letter_spacing{
      letter-spacing:initial;
      font-size: 12px !important;
  }

  #pin p { font-size: 1.2em; }

  .numbers {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-align-content: flex-end;
    -ms-flex-line-pack: end;
    align-content: flex-end;
    margin: 1em 0;
  }

  .number {
    position: relative;
    width: 2.5em;
    height: 2.5em;
    margin: 0.5em;
    border-radius: 2.5em;
    border: 2px solid rgb(154 27 46);
    text-align: center;
    line-height: 2.5em;
    font-weight: 400;
    font-size: 1.8em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    cursor: pointer;
  }

  .number:hover { color: rgba(243, 134, 134, 0.5); }

  .number:hover:before { border: 2px solid rgba(255, 255, 255, 0.5); }

  .number:before {
    content: "";
    position: absolute;
    left: -2px;
    width: 2.5em;
    height: 2.5em;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 2.5em;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
  }
  .number.grow:before {
      -webkit-animation: grow .6s ease;
      animation: grow .6s ease;
      }
  @-webkit-keyframes 
  growDot {  100% {
  background: white;
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
  }
  }
  @keyframes 
  growDot {  100% {
  background: white;
  -webkit-transform: scale3d(0.9, 0.9, 0.9);
  transform: scale3d(0.9, 0.9, 0.9);
  }
  }
  @-webkit-keyframes 
  grow {  50% {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  }
  @keyframes 
  grow {  50% {
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5);
  }
  100% {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  }
  @-webkit-keyframes 
  wrong {  20% {
  background: crimson;
  }
  40% {
  -webkit-transform: translate(-15px, 0);
  transform: translate(-15px, 0);
  }
  60% {
  -webkit-transform: translate(10px, 0);
  transform: translate(10px, 0);
  }
  80% {
  -webkit-transform: translate(-5px, 0);
  transform: translate(-5px, 0);
  }
  }
  @keyframes 
  wrong {  20% {
  background: crimson;
  }
  40% {
  -webkit-transform: translate(-15px, 0);
  transform: translate(-15px, 0);
  }
  60% {
  -webkit-transform: translate(10px, 0);
  transform: translate(10px, 0);
  }
  80% {
  -webkit-transform: translate(-5px, 0);
  transform: translate(-5px, 0);
  }
  }
  @-webkit-keyframes 
  correct {  20% {
  background: limegreen;
  }
  40% {
  -webkit-transform: translate(0, -15px);
  transform: translate(0, -15px);
  }
  60% {
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
  }
  80% {
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
  }
  }
  @keyframes 
  correct {  20% {
  background: limegreen;
  }
  40% {
  -webkit-transform: translate(0, -15px);
  transform: translate(0, -15px);
  }
  60% {
  -webkit-transform: translate(0, 10px);
  transform: translate(0, 10px);
  }
  80% {
  -webkit-transform: translate(0, -5px);
  transform: translate(0, -5px);
  }
  }
  @-webkit-keyframes 
  bg-red {  50% {
  background: crimson;
  }
  }
  @keyframes 
  bg-red {  50% {
  background: crimson;
  }
  }
  @-webkit-keyframes 
  bg-green {  50% {
  background: limegreen;
  }
  }
  @keyframes 
  bg-green {  50% {
  background: limegreen;
  }
  }
  #pin >.v-input input {
      text-align: center !important;
      font-size: 35px !important;
  }
  .td-invioce td{
      border: thin solid rgba(0, 0, 0, 0.12);
  }
  .tb-pong td{
      border-bottom: none !important;
      height: 20px !important;
  }
  .th-invoice th{
    border-top: thin solid rgba(0, 0, 0, 0.12) !important;  
  }
  .v-list-item-left {
      padding: 0 10px !important;
  }
  .theme--light.v-divider{
      border-color: rgb(0 0 0 / 0%);
  }


  /* Surface pro */
  @media only screen and (min-width : 1400px){
      .btn-funtion{
          font-size: 14px !important;
      }
      .v-application--is-ltr .v-btn__content .v-icon--left {
          margin-right: 8px;
      }
      .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
          font-size: 18px;
          height: 18px;
          width: 18px;
      }
      .calculator-btn{
          font-size: 14px;
      }
      
  }
 @media only screen and (min-device-height : 720px) and (max-device-width : 1280px) {
  
      /** Surface Pro styles here **/
      .btn-funtion{
          font-size: 12px !important;
      }
      .v-application--is-ltr .v-btn__content .v-icon--left {
          margin-right: 5px;
      }
      .v-btn__content .v-icon.v-icon--left, .v-btn__content .v-icon.v-icon--right {
          font-size: 16px;
          height: 10px;
          width: 10px;
      }
      .calculator-btn{
          font-size: 12px;
      }
  }
  .angle_action {
      right: 0;
      z-index: 10;
      position: absolute;
      top: 60px;
  }
  .angle_action_small {
      right: 12px;
      z-index: 10;
      position: absolute;
      top: 60px;
  }
  .b-order_type:before {
      content: "\e933";
      color: #ffffff;
      font-size: 22px;
  }
  .b-reward_s:before {
      content: "\e937";
      color: #ffffff;
      font-size: 22px;
  }
  .b-promotion:before {
      content: "\e936";
      color: #ffffff;
      font-size: 22px;
  }
  .b-count_guest:before {
      content: "\e935";
      color: #ffffff;
      font-size: 22px;
  }
  .b-note_s:before {
      content: "\e932";
      color: #ffffff;
      font-size: 22px;
  }
  .b-delivery_s:before {
      content: "\e931";
      color: #ffffff;
      font-size: 22px;
  }
  .b-parksale:before {
      content: "\e934";
      color: #ffffff;
      font-size: 22px;
  }
  .b-invoice_s:before {
      content: "\e930";
      color: #ffffff;
      font-size: 22px;
  }

</style>